'use client'

import styles from './Navbar.module.scss'
import classNames from 'classnames/bind'

import Button from '@/components/Links/Button/Button'
import Logo from '@/components/Elements/Logo/Logo'
import useIntersectionObserver from '@/components/Helpers/UseIntersectionObserver/UseIntersectionObserver'
import WindowSizeListener from '@/components/Helpers/WindowSizeListener/WindowSizeListener'

import { useEffect, useState, useRef } from 'react'
import { getCookie, setCookie } from '@/utils/index.js'

import PresentIcon from '@/icons/present.svg'
import SpecialOffer from '../SpecialOffer/SpecialOffer'
import NoticeBar from '../NoticeBar/NoticeBar'
import StaticMenuItems from './StaticMenuItems/StaticMenuItems'
import MobileMenu from './MobileMenu/MobileMenu'

const classes = classNames.bind(styles)

const NavbarContent = ({ menu, button, specialOffer, noticeBar, landingPage }) => {
    const [navbarType, setNavbarType] = useState('default')
    const [menuItems, setMenuItems] = useState(menu?.data?.attributes?.menuItems)
    const [sticky, setSticky] = useState(!noticeBar)

    const [specialOfferOpen, setSpecialOfferOpen] = useState(false)

    useEffect(() => {
        if (document.getElementById('main-gradient')) {
            setNavbarType('gradient')
        } else {
            setNavbarType('default')
        }

        function check_iOS() {
            return (
                ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
                    navigator.platform
                ) ||
                // iPad on iOS 13 detection
                (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
            )
        }

        if (check_iOS()) {
            setTimeout(() => {
                navbarRef.current.classList.add(classes('enter'))
                buttonRef.current.classList.add(classes('enter'))
            }, 150)
        }

        if (!menuItems.find((item) => item.id == 'pricing-menu-item')) {
            setMenuItems([
                {
                    id: 'pricing-menu-item',
                    mainLink: {
                        text: 'Pricing',
                        url: document.getElementById('pricing-section') ? '#pricing-section' : '/#pricing-section',
                    },
                },
                ...menuItems,
            ])
        }

        if (getCookie('specialOffer') != 'true') {
            setTimeout(() => {
                setSpecialOfferOpen(true)
            }, 1000)
            // Set cookie for 24 hours
            setCookie('specialOffer', 'true', 86400000)
        }

        const scrollListener = () => {
            // The height of the notice bar.
            if (window.scrollY > 44) {
                setSticky(true)
            } else {
                setSticky(false)
            }
        }

        if (noticeBar) {
            window.addEventListener('scroll', scrollListener)
        }

        return () => {
            try {
                window.removeEventListener('scroll', scrollListener)
            } catch (e) {}
        }
    }, [])

    const navbarRef = useRef(null)
    const buttonRef = useRef(null)

    useIntersectionObserver({
        refs: [navbarRef],
        callback: ({ isIntersecting }) => {
            if (isIntersecting) {
                navbarRef.current.classList.add(classes('enter'))
                buttonRef.current.classList.add(classes('enter'))
            }
        },
        options: {
            // a threshold of 0 will trigger our IntersectionObserver at the
            // base of the element – a threshold of 1 would trigger at the top.
            threshold: [0],
        },
    })

    return (
        <>
            {/* {noticeBar && <NoticeBar {...noticeBar} navbarType={navbarType} />} */}
            {sticky && landingPage && <div style={{ height: '60px' }} />}
            <div
                className={classes('navbar-wrap', navbarType, {
                    'landing-page': landingPage,
                    scrolled: landingPage && sticky,
                })}
                ref={navbarRef}
            >
                <div className={classes('navbar')}>
                    <a href="/" title="@Taxd logo link to homepage">
                        <Logo type={navbarType == 'default' ? 'primary' : 'white'} className={classes('logo')} />
                    </a>
                    <div className={classes('menu')}>
                        <StaticMenuItems type={navbarType} />
                        <SpecialOfferPopupWrapper
                            open={specialOfferOpen}
                            setOpen={setSpecialOfferOpen}
                            specialOffer={specialOffer}
                            noticeBar={noticeBar?.display}
                            navbarType={navbarType}
                        />
                        {button && (
                            <Button
                                href={button.url}
                                target={button.newTab ? '_blank' : null}
                                {...button}
                                type={button.type == 'white' && navbarType == 'default' ? 'primary' : button.type}
                                extraClasses={classes('button')}
                                ref={buttonRef}
                            />
                        )}
                    </div>
                    <div className={classes('mobile-options-wrap')}>
                        <SpecialOfferPopupWrapper
                            open={specialOfferOpen}
                            setOpen={setSpecialOfferOpen}
                            specialOffer={specialOffer}
                            noticeBar={noticeBar?.display}
                            navbarType
                            mobile={true}
                        />
                        <MobileMenu navbarType={navbarType} button={button} />
                    </div>
                </div>
            </div>
        </>
    )
}

const SpecialOfferPopupWrapper = ({ open, setOpen, specialOffer, mobile, noticeBar, navbarType }) => {
    const buttonRef = useRef(null)
    const [loaded, setLoaded] = useState(false)
    const [windowMobile, setWindowMobile] = useState(false)

    useEffect(() => {
        WindowSizeListener('959px', setWindowMobile)
        setLoaded(true)
    }, [])

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (buttonRef.current && !buttonRef.current.contains(e.target)) {
                setOpen(false)
            }
        }

        if (open) {
            document.addEventListener('mousedown', handleClickOutside)
        } else {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [open])

    if (!specialOffer?.display || !loaded) return null
    if ((windowMobile && !mobile) || (!windowMobile && mobile)) return null

    return (
        <div className={classes('special-offer-menu-wrapper')}>
            <div className={classes('special-offer-menu-option')} onClick={() => setOpen(!open)} ref={buttonRef}>
                <PresentIcon className={classes({ dark: navbarType == 'default' })} />
            </div>
            <SpecialOffer open={open} setOpen={setOpen} extraHeightTop={noticeBar} {...specialOffer} />
        </div>
    )
}

export default NavbarContent
